



































































import { Component } from 'vue-property-decorator';
import { ReportChartRingComponent } from '@/mixins/report-chart-base';
import CameraIndexService from './service/camera-index';

@Component
export default class CameraIndexComponent extends ReportChartRingComponent {
    headerCardList: Array<any> = [
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device.png' },
        { title: '在线设备数', count: null, unit: '台', loading: true, icon: '/img/device-online.png' },
        { title: '离线设备数', count: null, unit: '台', loading: true, icon: '/img/device-offline.png' },
        { title: '今天告警总数', count: null, unit: '条', loading: true, icon: '/img/alarm-total.png' },
        { title: '今天未处理告警', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' }
    ];

    alarmStatusCountData: any = {};
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    facilityStatusData: any = {};
    facilityStatusDataLoading: boolean = true;

    facilityLocationData: any = null;
    facilityLocationDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        CameraIndexService.getFacilityCount().then(res => {
            this.headerCardList[0].count = res.totalCount;
            this.headerCardList[1].count = res.onlineCount;
            this.headerCardList[2].count = res.offlineCount;
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
        });
        CameraIndexService.getAlarmCount().then(res => {
            this.headerCardList[3].count = res.totalCount;
            this.headerCardList[4].count = res.unsolvedCount;
        }).finally(() => {
            this.headerCardList[3].loading = false;
            this.headerCardList[4].loading = false;
        });

        CameraIndexService.getFacilityStatusCount().then(res => {
            this.facilityStatusData = res;
        }).finally(() => this.facilityStatusDataLoading = false);

        CameraIndexService.getFacilityLocationCount().then(res => {
            this.facilityLocationData = res;
        }).finally(() => this.facilityLocationDataLoading = false);

        CameraIndexService.getLast7DayAlarmCount().then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);

        CameraIndexService.getAlarmStatusCountByType().then(res => {
            this.alarmStatusCountData = res;
        }).finally(() => this.alarmStatusCountDataLoading = false);
    }
}
