import { get, post } from '@/service/request';
import { dateFormat } from '@/filter';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem`;
class CameraIndexService {
    /**
     * 查询告警数量
     * @returns
     */
    async getAlarmCount() {
        const resStatusData = await get(`${URL_PATH}/alarm/rts/STATUS/count`);
        const resTotalData = await get(`${URL_PATH}/alarm/rts/count`);
        return {
            totalCount: resTotalData.totalCount,
            unsolvedCount: resStatusData.unsolvedCount
        };
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }
    /**
     * 设备状态统计
     * @returns
     */
    async getFacilityStatusCount(params?: any) {
        const url = `${URL_PATH}/facility/rts/statusCountByLocation`;
        const res = await post(url, params);
        return {
            data: {
                columns: ['locationName', 'onlineCount', 'offlineCount'],
                rows: res
            },
            settings: {
                labelMap: { onlineCount: '在线', offlineCount: '离线' },
                stack: { stackCount: ['onlineCount', 'offlineCount'] }
            },
            extend: {
                series: {
                    label: {
                        show: true,
                        color: '#fff',
                        position: 'inside'
                    }
                }
            }
        };
    }

    /**
     * 设备分布统计
     * @returns
     */
    async getFacilityLocationCount(params?: any) {
        const url = `${URL_PATH}/facility/rts/countByLocation`;
        const res = await post(url, params);
        return {
            columns: ['locationName', 'totalCount'],
            rows: res
        };
    }

    /**
     * 事件分类状态统计
     * @returns
     */
    async getAlarmStatusCountByType(params?: any) {
        let res = null;
        if (params) {
            const url = `${URL_PATH}/alarm/hts/statusCountByType`;
            res = await post(url, params);
        } else {
            const url = `${URL_PATH}/alarm/rts/statusCountByType`;
            res = await get(url);
        }
        return {
            data: {
                columns: ['name', 'solvedCount', 'unsolvedCount'],
                rows: res
            },
            settings: {
                labelMap: { solvedCount: '已处理', unsolvedCount: '未处理' },
                stack: { stackCount: ['solvedCount', 'unsolvedCount'] }
            },
            extend: {
                series: {
                    label: {
                        show: true,
                        color: '#fff',
                        position: 'inside'
                    }
                }
            }
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount(params?: any) {
        let res = null;
        if (params) {
            const url = `${URL_PATH}/alarm/hts/countTrends`;
            res = await post(url, params);
        } else {
            const url = `${URL_PATH}/alarm/rts/countTrends`;
            res = await get(url);
        }
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }
}

export default new CameraIndexService();
